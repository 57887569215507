<template>
  <ModalMain
    @close="close"
  >
    <div class="px-4 md:px-7 pt-4 w-full">
      <div
        v-if="!showDepositCompleted"
        class="animate-fade-in"
      >
        <div class="text-center mb-3 flex items-center justify-center">
          <span class="icon-ico-eth text-2xl text-blue-500 animate-glow-slow-blue text-center mr-2"/>
          <h1 class="text-3xl uppercase tracking-widest text-center">{{ title }}</h1>
        </div>
        <div class="text-center block">
          <h2 class="text-base uppercase tracking-wider mt-1 text-center text-cyan">Add ETH to your MetaWin Balance</h2>
          <h2 v-if="tournamentSelected?.name" class="text-2xl uppercase tracking-widest mt-1 text-center text-cyan">{{ tournamentSelected.name }}</h2>
        </div>

        <VForm
          v-slot="{ values, invalid }"
          ref="DepositForm"
          :validation-schema="validationSchema"
          class="mt-5"
          @submit="onSubmit"
        >
          <div class="relative">
            <InputValidated
              ref="DepositInput"
              name="depositAmount"
              type="number"
              step="0.01"
              :max-decimal-places="6"
              hide-validation-tick
              :value="depositAmount"
              :disabled="isLoading || !depositAvailable"
              @keypress="$preventLetterInput($event)"
              @input="updateValues(values)"
            />
          </div>

          <div class="flex justify-between mt-2 overflow-hidden">
            <div
              v-for="(preset, index) in presets"
              :key="'preset' + index"
            >
              <ButtonPreset
                :disabled="isLoading || !depositAvailable"
                :preset="preset"
                :preset-selected="depositAmount"
                class="w-12 3xs:w-14 xs:w-[62px] text-sm xs:text-base"
                @click.prevent.stop="usePreset(preset)"
              >
                {{ preset }}
              </ButtonPreset>
            </div>
          </div>
          <ButtonButton
            :is-loading="isLoading"
            type="submit"
            :disabled="invalid || isLoading || !depositAvailable"
            class="block w-full mt-8"
          >
            Add funds
          </ButtonButton>

          <p
            v-if="!depositAvailable"
            class="text-center mt-4 text-white text-sm"
          >
            Deposits from the country you are connecting from are not possible at this time.
          </p>

          <h2
            v-if="txHash"
            class="animate-pulse text-white mt-4 text-center"
          >
            We're processing the transaction.
          </h2>

          <div class="text-center mt-5">
            <span class="icon-ico-check-circle-custom text-4xl text-green-50"/>
            <p class="uppercase text-pink-400 leading-tight text-sm tracking-wide">
            <strong class="tracking-wider text-base">Zero Friction Guarantee</strong> <br>
            Your withdrawals are <strong>instant</strong> up to 3 ETH in 24H
            </p>
            <p class="leading-none text-[11px] mt-3 uppercase">Withdrawals must return funds to the same wallet as deposit</p>
          </div>
        </VForm>
      </div>

      <div
        v-if="showDepositCompleted"
        class="animate-fade-in"
      >
        <div class="text-center block">
          <span class="icon-ico-eth text-[42px] text-blue-500 animate-glow-slow-blue text-center"/>
          <h1 class="text-3xl uppercase tracking-widest mt-5 text-center">Balance updated</h1>
          <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s">
            <p>Your balance has been updated</p>
          </div>
          <ButtonButton
            type="button"
            class="block w-full mt-8 animate-slide-down-fade-in05s"
            @click.prevent.stop="close()"
          >
            Close
          </ButtonButton>
        </div>
      </div>
    </div>
  </ModalMain>
</template>

<script>
import * as VeeValidate from 'vee-validate';
import * as yup from 'yup';
import { mapWritableState, mapActions, mapState } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useCryptoStore } from '@/store/crypto';
import { useBankingStore } from '@/store/banking';
import { useTournamentStore } from '@/store/tournaments';
import { useUserStore } from '@/store/user';

export default defineComponent({
  name: 'ModalDepositMain',
  components: {
    VForm: VeeValidate.Form,
  },
  data() {
    return {
      isLoading: false,
      nfts: [],
      metaMaskErrorMessage: '',
      depositAmount: 0.1,
      minDeposit: 0.025,
      maxDeposit: 1000,
      presets: [
        0.05,
        0.1,
        0.25,
        0.5,
        1,
      ],
      txHash: null,
    };
  },
  computed: {
    ...mapWritableState(useUiStore, ['showDepositModal', 'depositModalVariant',]),
    ...mapWritableState(useBankingStore, ['showDepositCompleted',]),
    ...mapWritableState(useTournamentStore, ['tournamentSelected',]),
    ...mapState(useCryptoStore, ['exchangeRateEthUsd',]),
    ...mapState(useUserStore, ['userFeatures',]),
    validationSchema() {
      // let patternTwoDigisAfterComma = /^\d+(\.\d{0,6})?$/;

      return yup.object().shape({
        depositAmount: yup
        .number()
        .positive()
        .min(this.minDeposit, `Minimum deposit is ${this.minDeposit}`)
        .max(this.maxDeposit, `Maximum deposit is ${this.maxDeposit}`)
        .required('Please enter a numeric value')
        .typeError('Please enter a numeric value')
        .label('Deposit amount'),
      });
    },
    title() {
      switch (this.depositModalVariant) {
        case 'insufficientBalance':
          return 'Fill your balance';
        case 'depositToPlay':
          return 'Deposit to play';
        default:
          return 'Make a Deposit';
      }
    },
    valUsdDisplay() {
      const priceUsd = this.depositAmount * this.exchangeRateEthUsd;
      if (priceUsd < 1000) {
        return this.depositAmount !== null ? this.$truncateNumber(priceUsd, 2) : '';
      } else {
        return this.depositAmount !== null ? this.$formatNumber(priceUsd, 2) : '';
      }
    },
    depositAvailable() {
      return this.userFeatures?.deposit || false;
    },
  },
  mounted() {
    // Adjust default deposit value to the cost of selected tournament, if selected
    const entryCost = Number(this.tournamentSelected?.playerEntryCost) || null;
    if (this.depositModalVariant === 'depositToPlay' && entryCost) {
      entryCost <= this.minDeposit ? this.depositAmount = this.minDeposit : this.depositAmount = entryCost;
      this.depositAmount = entryCost > this.maxDeposit ? this.maxDeposit : null;
    }
  },
  methods: {
    ...mapActions(useCryptoStore, ['startTransaction',]),
    updateValues(values) {
      this.depositAmount = values.depositAmount;
    },
    close() {
      this.showDepositModal = false;
      this.depositModalVariant = 'normal';
      this.tournamentSelected = null;
    },
    async onSubmit() {
      if (!this.depositAvailable) { return; }

      this.isLoading = true;
      try {
        this.txHash = await this.startTransaction(this.depositAmount);
      } catch (e) {
        if (e.code === 4001) {
          useNuxtApp().$rollbar.error(`Deposit cancelled: ${e}`);
        } else {
          useNuxtApp().$rollbar.error(`onSubmit failed: ${e}`);
        }
        this.isLoading = false;
      }
    },
    usePreset(val) {
      this.$refs.DepositInput.inputValue = val;
      this.depositAmount = val;
    },
  },
});
</script>
